import {
  KDC_MINOR_CATEGORY,
  KdcCategoryType,
} from "../nb/category/nbCategory.models";

export const kdcPrices = {
  게임개발종합반: 49000,
  데이터분석종합반: 49000,
  앱개발종합반: 49000,
  금융인을위한파이썬업무자동화: 49000,
  웹개발종합반: 49000,
  웹개발의봄Spring: 49000,
  노코드종합반: 49000,
  "ChatGPT300%활용하기": 49000,
  AI가처음이어도쉽게배우는생성형AIAtoZ: 49000,
  클라우드가처음이어도쉽게배우는AWSAtoZ: 29000,
};

export type NbCourse = {
  kdcCourseId: string;
  originalTitle: string;
  hrdNetTitle: string;
  unifyingDisplayTitle: string;
  url: string;
  kdcKeyword: string;
  duration: number;
  displayPrice: number;
  price: number;
  tracseId: string;
  tag: "왕초보" | "스킬업";
  category: KdcCategoryType[];
  description?: string;
  desktopPopupImageX3: string;
  mobilePopupImageX3: string;
  desktopHeroImageX3: string;
  desktopInfoImageX3: string;
  desktopCurriculumImageX3: string;
  mobileHeroImageX3: string;
  mobileInfoImageX3: string;
  mobileCurriculumImageX3: string;

  desktopPopupImageX2: string;
  mobilePopupImageX2: string;
  desktopHeroImageX2: string;
  desktopInfoImageX2: string;
  desktopCurriculumImageX2: string;
  mobileHeroImageX2: string;
  mobileInfoImageX2: string;
  mobileCurriculumImageX2: string;
  previewLink: string;
  amount?: string;
  deadline?: Date;
  priority: number;
  setCourseId?: string;
  card_tags?: string[];

  productId?: number;
  kdcProductId?: number;
  isOff?: boolean;
};

export type NbCourseNotShowing = Omit<
  NbCourse,
  | "tag"
  | "category"
  | "priority"
  | "desktopPopupImageX3"
  | "mobilePopupImageX3"
  | "desktopHeroImageX3"
  | "desktopInfoImageX3"
  | "desktopCurriculumImageX3"
  | "mobileHeroImageX3"
  | "mobileInfoImageX3"
  | "mobileCurriculumImageX3"
  | "desktopPopupImageX2"
  | "mobilePopupImageX2"
  | "desktopHeroImageX2"
  | "desktopInfoImageX2"
  | "desktopCurriculumImageX2"
  | "mobileHeroImageX2"
  | "mobileInfoImageX2"
  | "mobileCurriculumImageX2"
  | "previewLink"
>;

export const gpt_300_online: NbCourse = {
  kdcCourseId: "64b687dc6e951768d4879e70",
  originalTitle: "ChatGPT 300% 활용하기",
  hrdNetTitle: "[왕초보] ChatGPT 300% 활용하기",
  unifyingDisplayTitle: "ChatGPT 300% 활용하기",
  url: "gpt_300_online",
  kdcKeyword: "GPT_300",
  duration: 6,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20230000455855",
  tag: "왕초보",
  card_tags: ["실무활용", "최신 트렌드"],
  category: [KDC_MINOR_CATEGORY.AI_GPT, KDC_MINOR_CATEGORY.BEST],
  description:
    "업무에 쓰는 GPT 활용법은 따로 있어요. 지금 바로 내 생산성을 3배 높이세요!",
  amount: "6주 과정 (주 2시간 분량)",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_gpt_300_online_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_gpt_300_online_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_gpt_300_online_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_gpt_300_online_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_gpt_300_online_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_gpt_300_online_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_gpt_300_online_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_gpt_300_online_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_gpt_300_online_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_gpt_300_online_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_gpt_300_online_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_gpt_300_online_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_gpt_300_online_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_gpt_300_online_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_gpt_300_online_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_gpt_300_online_popup_curriculum_2x.webp`,
  priority: 1,
  previewLink: "https://www.youtube.com/embed/8uPBXCxnSXI?autoplay=1",
  productId: 34,
  kdcProductId: 102,
};

export const sql_2023: NbCourse = {
  kdcCourseId: "65d2b38b1076f1d4cc075361",
  originalTitle: "엑셀보다 쉽고 빠른 SQL",
  hrdNetTitle: "[왕초보] 엑셀보다 쉽고 빠른 SQL",
  unifyingDisplayTitle: "엑셀보다 쉽고 빠른 SQL",
  url: "sql_2023",
  kdcKeyword: "kdc_sql_v2",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459628",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.BEST, KDC_MINOR_CATEGORY.DATA_AUTOMATION],
  description: "현직 데이터 분석가에게 실무에 필요한 SQL 스킬만 배우세요!",
  amount: "5주 과정 (주 2시간 분량)",
  previewLink: "https://www.youtube.com/embed/twjARPhs3dY?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_sql_2023_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_sql_2023_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_sql_2023_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_sql_2023_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_sql_2023_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_sql_2023_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_sql_2023_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_sql_2023_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_sql_2023_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_sql_2023_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_sql_2023_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_sql_2023_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_sql_2023_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_sql_2023_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_sql_2023_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_sql_2023_popup_curriculum_2x.webp`,
  priority: 8,
  card_tags: ["실무활용"],
  productId: 8,
  isOff: true,
  kdcProductId: 135,
};

const web_basic: NbCourse = {
  kdcCourseId: "64af9cbb47cba538dd738f98",
  originalTitle: "웹개발 종합반",
  hrdNetTitle: "[왕초보] 코딩이 처음이어도 쉽게 배우는 웹개발 A to Z",
  unifyingDisplayTitle: "코딩이 처음이어도 쉽게 배우는 웹개발 A to Z",
  url: "web_basic",
  kdcKeyword: "web_basic",
  duration: 5,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20230000455893",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.DEV, KDC_MINOR_CATEGORY.BEST],
  description:
    "4만 명이 수강한 베스트 강의! 코딩이 처음이라면 이 강의로 시작하세요.",
  amount: "5주 과정 (주 2시간 분량)",
  previewLink: "https://www.youtube.com/embed/cCR0qiT4KpI?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_web_basic_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_web_basic_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_web_basic_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_web_basic_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_web_basic_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_web_basic_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_web_basic_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_web_basic_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_web_basic_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_web_basic_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_web_basic_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_web_basic_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_web_basic_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_web_basic_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_web_basic_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_web_basic_popup_curriculum_2x.webp`,
  priority: 3,
  card_tags: ["왕초보 추천"],
  productId: 46,
  kdcProductId: 103,
};

const app: NbCourse = {
  kdcCourseId: "63ca26845b3bac30b29bf2a3",
  originalTitle: "앱개발 종합반",
  hrdNetTitle: "[왕초보] 플러터(Flutter)로 시작하는 앱개발 종합반",
  unifyingDisplayTitle: "플러터(Flutter)로 시작하는 앱개발 종합반",
  url: "app",
  kdcKeyword: "g_flutter",
  duration: 5,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20230000413063",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.DEV],
  description: "앱 기획부터 개발, 스토어 배포까지 A to Z를 함께 합니다.",
  previewLink: "https://www.youtube.com/embed/WkyigdyW3Qo?autoplay=1",
  amount: "5주 과정 (주 2시간 분량)",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_app_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_app_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_app_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_app_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_app_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_app_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_app_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_app_popup_curriculum_3x.webp`,
  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_app_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_app_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_app_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_app_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_app_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_app_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_app_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_app_popup_curriculum_2x.webp`,
  priority: 4,
  card_tags: ["부수입 창출"],
  productId: 57,
  kdcProductId: 104,
};

const data_v2: NbCourse = {
  kdcCourseId: "63ca5b43dfbe98f4c8ef20bf",
  originalTitle: "데이터 분석 종합반",
  hrdNetTitle: "[왕초보] 마케터, 기획자를 위한 실전 데이터 분석",
  unifyingDisplayTitle: "마케터, 기획자를 위한 실전 데이터 분석",
  url: "data_v2",
  kdcKeyword: "g_data",
  duration: 5,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20230000413029",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.DATA_AUTOMATION],
  description: "데이터 기반 사고는 물론, 시각화 스킬까지 갖춰 보세요.",
  previewLink: "https://www.youtube.com/embed/nfESkVyxuM8?autoplay=1",
  amount: "5주 과정 (주 2시간 분량)",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_data_v2_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_data_v2_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_data_v2_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_data_v2_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_data_v2_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_data_v2_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_data_v2_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_data_v2_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_data_v2_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_data_v2_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_data_v2_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_data_v2_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_data_v2_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_data_v2_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_data_v2_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_data_v2_popup_curriculum_2x.webp`,

  priority: 5,
  card_tags: ["시각화", "대시보드"],
  productId: 49,
  kdcProductId: 124,
};

export const workflow_automation: NbCourse = {
  kdcCourseId: "65d2ae1b1076f1d4cc073fd0",
  originalTitle: "칼퇴를 부르는 파이썬 업무 자동화",
  hrdNetTitle: "[왕초보] 칼퇴를 부르는 파이썬 업무 자동화",
  unifyingDisplayTitle: "칼퇴를 부르는 파이썬 업무 자동화",
  url: "workflow_automation",
  kdcKeyword: "kdc_python_automation",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459619",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.DATA_AUTOMATION],
  previewLink: "https://www.youtube.com/embed/ZKXI-pYr390?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_workflow_automation_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_workflow_automation_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_workflow_automation_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_workflow_automation_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_workflow_automation_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_workflow_automation_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_workflow_automation_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_workflow_automation_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_workflow_automation_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_workflow_automation_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_workflow_automation_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_workflow_automation_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_workflow_automation_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_workflow_automation_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_workflow_automation_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_workflow_automation_popup_curriculum_2x.webp`,
  priority: 7,
  kdcProductId: 133,
};

export const marketing_ga4: NbCourse = {
  kdcCourseId: "65d2a90040ddbb5b4661f7b0",
  originalTitle: "GA4와 SQL로 데이터 마케팅 한번에 끝내기",
  hrdNetTitle: "[왕초보] GA4와 SQL로 데이터 마케팅 한번에 끝내기",
  unifyingDisplayTitle: "GA4와 SQL로 데이터 마케팅 한번에 끝내기",
  url: "marketing_ga4",
  kdcKeyword: "kdc_marketing_ga4",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459614",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.DATA_AUTOMATION, KDC_MINOR_CATEGORY.BEST],
  description: "GA4와 SQL로 데이터에 숨겨진 고객의 '진짜 니즈'를 알아보세요!",
  amount: "4주 과정 (주 2시간 분량)",
  previewLink: "https://www.youtube.com/embed/uL__Xvi8RM4?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_marketing_ga4_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_marketing_ga4_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_marketing_ga4_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_marketing_ga4_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_marketing_ga4_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_marketing_ga4_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_marketing_ga4_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_marketing_ga4_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_marketing_ga4_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_marketing_ga4_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_marketing_ga4_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_marketing_ga4_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_marketing_ga4_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_marketing_ga4_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_marketing_ga4_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_marketing_ga4_popup_curriculum_2x.webp`,
  priority: 2,
  card_tags: ["마케터 추천"],
  isOff: true,
  kdcProductId: 129,
};

export const nocode_ai_app: NbCourse = {
  kdcCourseId: "65d2acd2a645a72c5e4a08ad",
  originalTitle: "노코드로 수익형 AI 앱 만들기",
  hrdNetTitle: "[왕초보] 노코드로 수익형 AI 앱 만들기",
  unifyingDisplayTitle: "노코드로 수익형 AI 앱 만들기",
  url: "nocode_ai_app",
  kdcKeyword: "kdc_nocode_ai_app",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459617",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.AI_GPT, KDC_MINOR_CATEGORY.DEV],
  description: "코딩 전혀 못해도 앱을 만들어 부수입 버는 방법을 배워 보세요.",
  amount: "5주 과정 (주 2시간 분량)",
  previewLink: "https://www.youtube.com/embed/yFEcjg7ERQg?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_app_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_app_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_app_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_app_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_app_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_app_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_app_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_app_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_app_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_app_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_app_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_app_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_app_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_app_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_app_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_app_popup_curriculum_2x.webp`,
  priority: 9,
  card_tags: ["문과생 추천"],
  productId: 28,
  kdcProductId: 125,
};

const nocode_ai_automation: NbCourse = {
  kdcCourseId: "65d2a583af84d15811dc9aa2",
  originalTitle: "AI와 노코드로 업무 자동화 끝내기",
  hrdNetTitle: "[왕초보] AI와 노코드로 업무 자동화 끝내기",
  unifyingDisplayTitle: "AI와 노코드로 업무 자동화 끝내기",
  url: "nocode_ai_automation",
  kdcKeyword: "kdc_nocode_ai_automation",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459613",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.AI_GPT],
  description: "귀찮은 단순반복업무, AI로 쉽게 자동화하는 법을 배워보세요.",
  amount: "4주 과정 (주 2시간 분량)",
  previewLink: "https://www.youtube.com/embed/ZwQ-6a82BnY?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_automation_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_automation_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_automation_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_automation_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_automation_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_automation_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_automation_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_automation_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_automation_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_automation_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_automation_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_automation_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_nocode_ai_automation_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_automation_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_automation_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_nocode_ai_automation_popup_curriculum_2x.webp`,
  priority: 10,
  card_tags: ["실무활용", "자동화 치트키"],
  isOff: true,
  kdcProductId: 128,
};

const webflow: NbCourse = {
  kdcCourseId: "64b0a2237be3bd117c4ec29a",
  originalTitle: "노코드 종합반",
  hrdNetTitle: "[왕초보] 노코드로 완성도 있는 웹사이트 만들기",
  unifyingDisplayTitle: "노코드로 완성도 있는 웹사이트 만들기",
  url: "webflow",
  kdcKeyword: "webflow",
  duration: 5,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20230000455905",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.DEV],
  previewLink: "https://www.youtube.com/embed/-T28uLGq-xg?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_webflow_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_webflow_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_webflow_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_webflow_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_webflow_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_webflow_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_webflow_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_webflow_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_webflow_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_webflow_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_webflow_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_webflow_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_webflow_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_webflow_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_webflow_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_webflow_popup_curriculum_2x.webp`,
  priority: 101,
  card_tags: ["문과생 추천"],
  kdcProductId: 123,
};

const ai_analytics_blockchain: NbCourse = {
  kdcCourseId: "65d2ad561076f1d4cc073cb0",
  originalTitle: "AI 기반 투자 수익률 분석 실습을 통한 데이터 분석 역량 키우기",
  hrdNetTitle:
    "[스킬업] AI 기반 투자 수익률 분석 실습을 통한 데이터 분석 역량 키우기",
  unifyingDisplayTitle:
    "AI 기반 투자 수익률 분석 실습을 통한 데이터 분석 역량 키우기",
  url: "ai_analytics_blockchain",
  kdcKeyword: "kdc_ai_analytics_blockchain",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459618",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.AI_GPT],
  previewLink: "https://www.youtube.com/embed/EOlYdqo6HEE?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_ai_analytics_blockchain_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_ai_analytics_blockchain_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_ai_analytics_blockchain_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_ai_analytics_blockchain_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_ai_analytics_blockchain_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_ai_analytics_blockchain_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_ai_analytics_blockchain_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_ai_analytics_blockchain_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_ai_analytics_blockchain_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_ai_analytics_blockchain_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_ai_analytics_blockchain_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_ai_analytics_blockchain_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_ai_analytics_blockchain_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_ai_analytics_blockchain_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_ai_analytics_blockchain_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_ai_analytics_blockchain_popup_curriculum_2x.webp`,
  priority: 12,
  isOff: true,
  kdcProductId: 130,
};

const spring_v2: NbCourse = {
  kdcCourseId: "64b0a23c417100590a97e152",
  originalTitle: "웹개발의 봄, Spring",
  hrdNetTitle: "[개발자 입문] 웹개발의 봄, Spring",
  unifyingDisplayTitle: "웹개발의 봄, Spring",
  url: "spring_v2",
  kdcKeyword: "spring_v2",
  duration: 4,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20230000455897",
  tag: "스킬업",
  category: [KDC_MINOR_CATEGORY.DEV_WORK],
  previewLink: "https://www.youtube.com/embed/pKLLXxSsS5s?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_spring_v2_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_spring_v2_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_spring_v2_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_spring_v2_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_spring_v2_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_spring_v2_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_spring_v2_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_spring_v2_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_spring_v2_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_spring_v2_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_spring_v2_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_spring_v2_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_spring_v2_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_spring_v2_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_spring_v2_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_spring_v2_popup_curriculum_2x.webp`,
  priority: 103,
  kdcProductId: 116,
};

const interactive3dWeb: NbCourse = {
  kdcCourseId: "65d2ae8f1076f1d4cc07414b",
  originalTitle: "프론트엔드 개발자를 위한 인터랙티브 3D 웹 개발 실전",
  hrdNetTitle: "[스킬업] 프론트엔드 개발자를 위한 인터랙티브 3D 웹 개발 실전",
  unifyingDisplayTitle: "프론트엔드 개발자를 위한 인터랙티브 3D 웹 개발 실전",
  url: "3d_interactive_web",
  kdcKeyword: "kdc_3d_interactive_web",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459620",
  tag: "스킬업",
  category: [KDC_MINOR_CATEGORY.DEV],
  previewLink: "https://www.youtube.com/embed/iwx8nlfBfgo?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_interactive3dWeb_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_interactive3dWeb_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_interactive3dWeb_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_interactive3dWeb_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_interactive3dWeb_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_interactive3dWeb_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_interactive3dWeb_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_interactive3dWeb_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_interactive3dWeb_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_interactive3dWeb_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_interactive3dWeb_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_interactive3dWeb_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_interactive3dWeb_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_interactive3dWeb_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_interactive3dWeb_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_interactive3dWeb_popup_curriculum_2x.webp`,
  priority: 15,
  kdcProductId: 131,
};

const d_unity: NbCourse = {
  kdcCourseId: "65d2af11a5c2b2029a102ecb",
  originalTitle: "Unity로 메타버스 3D 게임 만들기",
  hrdNetTitle: "[왕초보] Unity로 메타버스 3D 게임 만들기",
  unifyingDisplayTitle: "Unity로 메타버스 3D 게임 만들기",
  url: "3d_game",
  kdcKeyword: "kdc_3d_game",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459621",
  tag: "스킬업",
  category: [KDC_MINOR_CATEGORY.DEV],
  previewLink: "https://www.youtube.com/embed/U89mbmx7Zwk?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_d_unity_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_d_unity_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_d_unity_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_d_unity_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_d_unity_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_d_unity_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_d_unity_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_d_unity_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_d_unity_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_d_unity_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_d_unity_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_d_unity_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_d_unity_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_d_unity_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_d_unity_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_d_unity_popup_curriculum_2x.webp`,
  priority: 16,
  kdcProductId: 126,
};

const bio_app: NbCourse = {
  kdcCourseId: "65d2af70a5c2b2029a1030ca",
  originalTitle: "Kotlin으로 만드는 공공데이터 활용 안드로이드 앱",
  hrdNetTitle: "[왕초보] Kotlin으로 만드는 공공데이터 활용 안드로이드 앱",
  unifyingDisplayTitle: "Kotlin으로 만드는 공공데이터 활용 안드로이드 앱",
  url: "bio_app",
  kdcKeyword: "kdc_bio_app",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459622",
  tag: "스킬업",
  category: [KDC_MINOR_CATEGORY.DEV],
  previewLink: "https://www.youtube.com/embed/W7ZTf6VALSM?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_bio_app_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_bio_app_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_bio_app_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_bio_app_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_bio_app_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_bio_app_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_bio_app_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_bio_app_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_bio_app_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_bio_app_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_bio_app_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_bio_app_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_bio_app_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_bio_app_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_bio_app_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_bio_app_popup_curriculum_2x.webp`,
  priority: 17,
  kdcProductId: 127,
};

const cicd: NbCourse = {
  kdcCourseId: "65d2b1111076f1d4cc074c62",
  originalTitle: "Docker 기반 CI/CD 파이프라인 구축하기",
  hrdNetTitle: "[스킬업] Docker 기반 CI/CD 파이프라인 구축하기",
  unifyingDisplayTitle: "Docker 기반 CI/CD 파이프라인 구축하기",
  url: "cicd",
  kdcKeyword: "kdc_cicd",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459625",
  tag: "스킬업",
  category: [],
  previewLink: "https://www.youtube.com/embed/1kdBAv5xUtI?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_cicd_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_cicd_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_cicd_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_cicd_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_cicd_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_cicd_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_cicd_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_cicd_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_cicd_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_cicd_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_cicd_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_cicd_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_cicd_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_cicd_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_cicd_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_cicd_popup_curriculum_2x.webp`,
  priority: 18,
  kdcProductId: 114,
};

const algorithm: NbCourse = {
  kdcCourseId: "65d2b315a5c2b2029a103d56",
  originalTitle: "실무에 바로 쓰이는 알고리즘 by Python",
  hrdNetTitle: "[스킬업] 실무에 바로 쓰이는 알고리즘 by Python",
  unifyingDisplayTitle: "실무에 바로 쓰이는 알고리즘 by Python",
  url: "algorithm",
  kdcKeyword: "kdc_algorithm",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459627",
  tag: "스킬업",
  category: [],
  previewLink: "https://www.youtube.com/embed/_XQF302-Kwk?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_algorithm_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_algorithm_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_algorithm_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_algorithm_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_algorithm_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_algorithm_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_algorithm_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_algorithm_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_algorithm_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_algorithm_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_algorithm_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_algorithm_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_algorithm_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_algorithm_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_algorithm_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_algorithm_popup_curriculum_2x.webp`,
  priority: 19,
  kdcProductId: 115,
};

const cloud_aws: NbCourse = {
  kdcCourseId: "6653f2e84a59c95dcbf75cf8",
  originalTitle: "클라우드가 처음이어도 쉽게 배우는 AWS A to Z",
  hrdNetTitle: "[스킬업] 클라우드가 처음이어도 쉽게 배우는 AWS A to Z",
  unifyingDisplayTitle: "클라우드가 처음이어도 쉽게 배우는 AWS A to Z",
  url: "cloud_aws",
  kdcKeyword: "certificate_aws_ssa",
  duration: 0,
  displayPrice: 290000,
  price: 29000,
  tracseId: "AIG20240000459623",
  tag: "스킬업",
  category: [KDC_MINOR_CATEGORY.DEV_WORK],
  previewLink: "https://www.youtube.com/embed/fP_IwPM3KOU?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_aws_cloud_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_aws_cloud_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_aws_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_aws_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_aws_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_aws_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_aws_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_aws_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_aws_cloud_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_aws_cloud_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_aws_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_aws_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_aws_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_aws_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_aws_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_aws_popup_curriculum_2x.webp`,
  priority: 20,
  card_tags: ["자격증 준비"],
  kdcProductId: 134,
};

const generative_ai: NbCourse = {
  kdcCourseId: "65d2b1e4a645a72c5e4a1f20",
  originalTitle: "AI가 처음이어도 쉽게 배우는 생성형 AI A to Z",
  hrdNetTitle: "[스킬업] AI가 처음이어도 쉽게 배우는 생성형 AI A to Z",
  unifyingDisplayTitle: "AI가 처음이어도 쉽게 배우는 생성형 AI A to Z",
  url: "generative_ai",
  kdcKeyword: "kdc_generative_ai",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000459626",
  tag: "왕초보",
  category: [KDC_MINOR_CATEGORY.AI_GPT],
  previewLink: "https://www.youtube.com/embed/1fPKKfG04PQ?autoplay=1",
  desktopPopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_generative_ai_popup_3x.webp`,
  mobilePopupImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_generative_ai_popup_3x.webp`,
  desktopHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_generative_ai_popup_hero_3x.webp`,
  desktopInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_generative_ai_popup_info_3x.webp`,
  desktopCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_generative_ai_popup_curriculum_3x.webp`,
  mobileHeroImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_generative_ai_popup_hero_3x.webp`,
  mobileInfoImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_generative_ai_popup_info_3x.webp`,
  mobileCurriculumImageX3: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_generative_ai_popup_curriculum_3x.webp`,

  desktopPopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_generative_ai_popup_2x.webp`,
  mobilePopupImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_generative_ai_popup_2x.webp`,
  desktopHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_generative_ai_popup_hero_2x.webp`,
  desktopInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_generative_ai_popup_info_2x.webp`,
  desktopCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/pc_generative_ai_popup_curriculum_2x.webp`,
  mobileHeroImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_generative_ai_popup_hero_2x.webp`,
  mobileInfoImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_generative_ai_popup_info_2x.webp`,
  mobileCurriculumImageX2: `${process.env.CDN_BASE_URL}/TeamSparta-Inc/scc-frontend/assets/images/kdc/popup/mo_generative_ai_popup_curriculum_2x.webp`,
  priority: 21,
  kdcProductId: 132,
};

export const kdc_gpt_web: NbCourseNotShowing = {
  kdcCourseId: "66b1ac3573d6338061730c73",
  originalTitle: "웹개발이 처음이어도 쉽게 배우는 GPT 웹개발",
  hrdNetTitle: "웹개발이 처음이어도 쉽게 배우는 GPT 웹개발",
  unifyingDisplayTitle: "웹개발이 처음이어도 쉽게 배우는 GPT 웹개발",
  url: "kdc_gpt_web",
  kdcKeyword: "kdc_gpt_web",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000499102",
  kdcProductId: 137,
};

export const kdc_nocode_chang: NbCourseNotShowing = {
  kdcCourseId: "66b18cb573d633806172cf1e",
  originalTitle: "창업이 처음이어도 쉽게 배우는 노코드 웹개발 창업",
  hrdNetTitle: "창업이 처음이어도 쉽게 배우는 노코드 웹개발 창업",
  unifyingDisplayTitle: "창업이 처음이어도 쉽게 배우는 노코드 웹개발 창업",
  url: "kdc_nocode_chang",
  kdcKeyword: "kdc_nocode_chang",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000499092",
  kdcProductId: 138,
};

const kdc_machinelearning_v1: NbCourseNotShowing = {
  kdcCourseId: "66b19c81daa18f1e0e9c2b67",
  originalTitle: "실무에 바로 쓰는 바닥부터 시작하는 머신러닝",
  hrdNetTitle: "실무에 바로 쓰는 바닥부터 시작하는 머신러닝",
  unifyingDisplayTitle: "실무에 바로 쓰는 바닥부터 시작하는 머신러닝",
  url: "kdc_machinelearning_v1",
  kdcKeyword: "kdc_machinelearning_v1",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000499100",
  kdcProductId: 117,
};

const kdc_deeplearning_v2: NbCourseNotShowing = {
  kdcCourseId: "66b2c50ad69fa23abab1a8ee",
  originalTitle: "실무에 바로 쓰는 기초가 탄탄한 딥러닝",
  hrdNetTitle: "실무에 바로 쓰는 기초가 탄탄한 딥러닝",
  unifyingDisplayTitle: "실무에 바로 쓰는 기초가 탄탄한 딥러닝",
  url: "kdc_deeplearning_v2",
  kdcKeyword: "kdc_deeplearning_v2",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000499101",
  kdcProductId: 118,
};

const kdc_unreal5_blueprint: NbCourseNotShowing = {
  kdcCourseId: "66b1989273d633806172f485",
  originalTitle:
    "3D 게임 개발이 처음이어도 언리얼+블루프린트로 쉽게 배우는 3D 게임 개발",
  hrdNetTitle:
    "3D 게임 개발이 처음이어도 언리얼+블루프린트로 쉽게 배우는 3D 게임 개발",
  unifyingDisplayTitle:
    "3D 게임 개발이 처음이어도 언리얼+블루프린트로 쉽게 배우는 3D 게임 개발",
  url: "kdc_unreal5_blueprint",
  kdcKeyword: "kdc_unreal5_blueprint",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000499097",
  kdcProductId: 139,
};

const kdc_react_skillup: NbCourseNotShowing = {
  kdcCourseId: "66b18c47daa18f1e0e9bf48f",
  originalTitle: "실무에 바로 쓰는 React",
  hrdNetTitle: "실무에 바로 쓰는 React",
  unifyingDisplayTitle: "실무에 바로 쓰는 React",
  url: "kdc_react_skillup",
  kdcKeyword: "kdc_react_skillup",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000499091",
  kdcProductId: 119,
};

export const kdc_ai_ppt: NbCourseNotShowing = {
  kdcCourseId: "66b19a79daa18f1e0e9c269c",
  originalTitle: "칼퇴를 부르는 AI PPT 업무 자동화",
  hrdNetTitle: "칼퇴를 부르는 AI PPT 업무 자동화",
  unifyingDisplayTitle: "칼퇴를 부르는 AI PPT 업무 자동화",
  url: "kdc_ai_ppt",
  kdcKeyword: "kdc_ai_ppt",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000499099",
  kdcProductId: 140,
};

const kdc_skill_nextjs: NbCourseNotShowing = {
  kdcCourseId: "66b197e6daa18f1e0e9c1d51",
  originalTitle: "실무에 바로 쓰는 Next.js",
  hrdNetTitle: "실무에 바로 쓰는 Next.js",
  unifyingDisplayTitle: "실무에 바로 쓰는 Next.js",
  url: "kdc_skill_nextjs",
  kdcKeyword: "kdc_skill_nextjs",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000499096",
  kdcProductId: 120,
};

const kdc_kubernetes: NbCourseNotShowing = {
  kdcCourseId: "66b192d773d633806172e2dc",
  originalTitle: "실무에 바로 쓰는 쿠버네티스 클라우드",
  hrdNetTitle: "실무에 바로 쓰는 쿠버네티스 클라우드",
  unifyingDisplayTitle: "실무에 바로 쓰는 쿠버네티스 클라우드",
  url: "kdc_ kubernetes",
  kdcKeyword: "kdc_ kubernetes",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000499094",
  kdcProductId: 121,
};

const kdc_FE_level_up: NbCourseNotShowing = {
  kdcCourseId: "66b187e1d69fa23abab02312",
  originalTitle:
    "시니어로 가는 지름길, 프론트엔드 실무 스킬(TDD, CI/CD, 성능 최적화)",
  hrdNetTitle:
    "시니어로 가는 지름길, 프론트엔드 실무 스킬(TDD, CI/CD, 성능 최적화)",
  unifyingDisplayTitle:
    "시니어로 가는 지름길, 프론트엔드 실무 스킬(TDD, CI/CD, 성능 최적화)",
  url: "kdc_FE_level_up",
  kdcKeyword: "kdc_FE_level_up",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000498978",
  kdcProductId: 122,
};

const kdc_tableau_visualization: NbCourseNotShowing = {
  kdcCourseId: "66b1965973d633806172ebbd",
  originalTitle: "5주 완성 데이터 시각화 마스터 클래스",
  hrdNetTitle: "5주 완성 데이터 시각화 마스터 클래스",
  unifyingDisplayTitle: "5주 완성 데이터 시각화 마스터 클래스",
  url: "kdc_tableau_visualization",
  kdcKeyword: "kdc_tableau_visualization",
  duration: 0,
  displayPrice: 490000,
  price: 49000,
  tracseId: "AIG20240000499095",
  kdcProductId: 136,
};

const tempOutOfSaleCourses = {
  // webflow, TODO: 변경 심사 통과후 다시 판매 재개
  spring_v2,
  spring: spring_v2,
  web_basic,
  gpt_300_online,
};

// TODO: KDC 허브, 가이드 페이지 노출하려면 데이터 추가하고 kdcCourseData 로 병합
export const tempNotShowingCourses: Record<string, NbCourseNotShowing> = {
  kdc_gpt_web,
  kdc_nocode_chang,
  kdc_machinelearning_v1,
  kdc_deeplearning_v2,
  kdc_unreal5_blueprint,
  kdc_react_skillup,
  kdc_ai_ppt,
  kdc_skill_nextjs,
  "kdc_ kubernetes": kdc_kubernetes,
  kdc_FE_level_up,
  kdc_tableau_visualization,
};

export const kdcCourseData: Record<string, NbCourse> = {
  app,
  workflow_automation,
  cicd,
  marketing_ga4,
  data_v2,
  ai_analytics_blockchain,
  nocode_ai_app,
  nocode_ai_automation,
  algorithm,
  bio_app,
  "3d_interactive_web": interactive3dWeb,
  "3d_unity": d_unity,
  "3d_game": d_unity,
  cloud_aws,
  generative_ai,
  sql_2023,
  sql_v2: sql_2023,
  ...tempOutOfSaleCourses,
};

export const kdcCoursesOnSale = {
  ...kdcCourseData,
  ...tempNotShowingCourses,
};

export const kdcAllCourseArray: NbCourseNotShowing[] | NbCourse[] =
  Object.values(kdcCoursesOnSale)
    .filter(
      (course, index, self) =>
        index === self.findIndex((t) => t.kdcCourseId === course.kdcCourseId),
    )
    .filter((course) => {
      const currentDate = new Date();
      return !course.deadline || course.deadline > currentDate;
    });

export const kdcCourseArray: NbCourse[] = Object.values(kdcCourseData)
  .filter(
    (course, index, self) =>
      index === self.findIndex((t) => t.kdcCourseId === course.kdcCourseId),
  )
  .filter((course) => !course.isOff)
  .filter((course) => {
    const currentDate = new Date();
    return !course.deadline || course.deadline > currentDate;
  });

export const kdcPopularCourseArray: NbCourse[] = kdcCourseArray.sort(
  (a, b) => a.priority - b.priority,
);
export const aiSpecialCourseArray: NbCourse[] = kdcCourseArray.filter(
  (course) =>
    course.category.includes(KDC_MINOR_CATEGORY.AI_GPT) &&
    course.url !== "gpt_300_online",
);
