import { useRouter } from "next/router";
import { sendLogV2 } from "../_common/loggingV2";
import { kdcCourseData } from "@/models/kdc/courses.model";
import { useGovRound } from "@/queries/nb";
import { sendCPLog } from "@teamsparta/cross-platform-logger";
import { getKdcCourseByProductId } from "../kdc";
import { useIsNarrow } from "@/businesslogics/_layout/window";
import { base64EncodeObject } from "@/components/organisms/aitc-leveltest/util";
import { useGetHrdTracse } from "../kdc/useHrdTracse";

export const useClickAnchor = (anchor) => {
  const router = useRouter();
  // const navigateWithLogin = useNavigateWithLoginV2();

  return () => {
    sendLogV2("gnb_click", router.asPath, { button_text: anchor.name });
    // outlink 인 경우에만 새창에서 띄우고, scc 도메인인 경우 router 로 이동
    anchor.href.includes("://") && !anchor.href.includes(process.env.ONLINE_URL)
      ? window.open(anchor.href)
      : anchor.name === "main"
        ? window.location.assign(anchor.href)
        : router.push({ pathname: anchor.href, query: router.query });
  };
};

export const useHrdEnrollUrl = (url) => {
  const courseData = kdcCourseData[url];
  const round = useGovRound(kdcCourseData[url]?.kdcKeyword);
  let tracseTime = round.data?.round.replace("회차", "");

  if (!courseData) return null;

  return `https://hrd.work24.go.kr/hrdp/co/pcobo/PCOBO0100P.do?tracseId=${courseData.tracseId}&tracseTme=${tracseTime}&crseTracseSe=C0105`;
};

const encodeHrdData = (tracseData: any, courseData: any) => {
  const hrdData = {
    tracseId: tracseData.tracse_id,
    tracseTime: tracseData.tracse_tme,
    courseData,
  };
  return base64EncodeObject(hrdData);
};

// KRR: product id 로 받아오도록 변경 필요
export const useHrdEnrollUrlByProductId = (productId: number) => {
  const courseData = getKdcCourseByProductId(productId);
  const { data: hrdTracseContext } = useGetHrdTracse(productId);
  const hrdTracseData = hrdTracseContext?.context;
  const encodedHrdQuery = encodeHrdData(hrdTracseData, courseData);

  const isMobile = useIsNarrow();

  if (!courseData) return null;
  if (!isMobile) {
    return `/kdc/hrd?enc_hrd=${encodedHrdQuery}`;
  }

  return `https://hrd.work24.go.kr/hrdp/co/pcobo/PCOBO0100P.do?tracseId=${hrdTracseData.tracse_id}&tracseTme=${hrdTracseData.tracse_tme}&crseTracseSe=C0105`;
};

export const useIsCurrentUrl = (href) => {
  const { pathname } = useRouter();

  return pathname === href;
};

export const useOnClickGnbItem = (href, buttonText) => {
  const router = useRouter();

  return () => {
    sendCPLog("nb_GuidePage_gnb_click", { button_text: buttonText });
    router.push(href);
  };
};
