import { privateInstance } from "../user/profile";
import {
  GetCardPossessionResponse,
  GetGovEnrollCountResponse,
  GetHasEnrolledRequest,
  GetHasEnrolledResponse,
  GetHrdTracseResponse,
  GetUserEnrollingGrsListResponse,
  GetUserEnrollStatusResponse,
  GetUserResidueCreditResponse,
  PostCardPossessionRequest,
  PostVirtualEnrollRequest,
  UserGrs,
} from "./dto";

export const getCardPossession = async () => {
  try {
    const response = await privateInstance.get<GetCardPossessionResponse>(
      `${process.env.ONLINE_API_URL}/v2/gov/card-possession`,
    );
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const postVirtualEnroll = async ({
  product_ids,
}: PostVirtualEnrollRequest) => {
  const response = await privateInstance.post(
    `${process.env.ONLINE_API_URL}/v2/gov/virtual-enrolled`,
    { product_ids },
  );
  return response.data;
};

export const stampStudyLead = async ({ course_ids }) => {
  try {
    const response = await privateInstance.post(
      `${process.env.ONLINE_API_URL}/v2/gov/stamp-study-lead`,
      { course_ids },
    );
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const postCardPossession = async ({
  status,
  product_ids,
  action,
  lead_type,
}: PostCardPossessionRequest) => {
  try {
    const response = await privateInstance.post(
      `${process.env.ONLINE_API_URL}/v2/gov/card-possession`,
      { status, product_ids, action, lead_type },
    );
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getHasEnrolled = async ({ course_id }: GetHasEnrolledRequest) => {
  try {
    const response = await privateInstance.get<GetHasEnrolledResponse>(
      `${process.env.ONLINE_API_URL}/v2/gov/has-enrolled/${course_id}`,
    );
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getUserGrs = async ({
  userId,
  roundId,
}: {
  userId: string;
  roundId: string;
}) => {
  try {
    const response = await privateInstance.get<UserGrs>(
      `${process.env.ONLINE_API_URL}/v2/gov/registration_status?user_id=${userId}&round_id=${roundId}`,
    );
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getGovEnrollCount = async () => {
  try {
    const response = await privateInstance.get<GetGovEnrollCountResponse>(
      `${process.env.ONLINE_API_URL}/v2/gov/enroll-count`,
    );
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getUserResidueCredit = async () => {
  try {
    const response = await privateInstance.get<GetUserResidueCreditResponse>(
      `${process.env.ONLINE_API_URL}/v2/gov/card-residue`,
    );
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getUserEnrollingGrsList = async () => {
  try {
    const response = await privateInstance.get<GetUserEnrollingGrsListResponse>(
      `${process.env.ONLINE_API_URL}/v2/kdc/registration/enrolling/list`,
    );
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getUserEnrollStatus = async (product_id: number) => {
  try {
    const response = await privateInstance.get<GetUserEnrollStatusResponse>(
      `${process.env.ONLINE_API_URL}/v2/kdc/registration/enroll-status/${product_id}`,
    );
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getHrdTracse = async (productId: number) => {
  const response = await privateInstance.get<GetHrdTracseResponse>(
    `${process.env.ONLINE_API_URL}/v2/kdc/registration/hrd-tracse/${productId}`,
  );
  return response.data;
};
